.characterPortraitContainer{
    background: #111111;
    border: 2px solid transparent;
    padding: 3px;
    height: 80px;
    width: 80px;
    position: relative;
    overflow: visible;
    margin-bottom: 10px;
    cursor: pointer;
    opacity: 0.5;
    margin-left: 5px;
    margin-right: 5px;
}

.characterSelected{
    border: 2px solid #B5AD7C;
    opacity: 1;
}

.characterPortraitInnerContainer{
    background: #2E2526;
    border: 2px solid #777777;
    margin: 2px;
    height: 72px;
    width: 72px;
}
.characterPortraitSelect{
    position: absolute;
    bottom: -10px;
    left: 40%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 10px;
    height: 10px;
    border: 3px solid #B5AD7C;
    background-color: #B5AD7C;
    transform: rotate(45deg);
}
.characterPortraitSelectInner{
    width: 80%;
    height: 80%;
    border: 1px solid #555555;
}
img {
    max-width: 100%;
    height: auto;
}