.startButton {
  color: white;
  background: #3b9720 no-repeat;
  border: none;
  font-family: "Rajdhani", sans-serif;
  font-size: 2em;
  text-shadow: 2px 2px 0px #000000;
  cursor: pointer;
  border-style: outset;
  border-color: #3b9720;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 250px;
}

.startButton:hover {
  background: #5bc23b no-repeat;
}
