@import url(https://fonts.googleapis.com/css2?family=Rajdhani:wght@600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #111111;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: "Rajdhani", sans-serif;
}

.artifactContainer {
  padding: 10px;
  border: 2px solid #555555;
  width: 80%;
  max-width: 800px;
  margin: 10px auto;
}

.mainContainer {
  margin: 20px auto;
  text-align: center;
  border: 1px solid #cdcdcd;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  background: rgba(0, 0, 0, 0.3);
  max-width: 850px;
  padding: 10px;
}

.sliderContainer {
  margin-right: 50px;
  margin-left: 50px;
}

.characterPortraitContainer{
    background: #111111;
    border: 2px solid transparent;
    padding: 3px;
    height: 80px;
    width: 80px;
    position: relative;
    overflow: visible;
    margin-bottom: 10px;
    cursor: pointer;
    opacity: 0.5;
    margin-left: 5px;
    margin-right: 5px;
}

.characterSelected{
    border: 2px solid #B5AD7C;
    opacity: 1;
}

.characterPortraitInnerContainer{
    background: #2E2526;
    border: 2px solid #777777;
    margin: 2px;
    height: 72px;
    width: 72px;
}
.characterPortraitSelect{
    position: absolute;
    bottom: -10px;
    left: 40%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 10px;
    height: 10px;
    border: 3px solid #B5AD7C;
    background-color: #B5AD7C;
    transform: rotate(45deg);
}
.characterPortraitSelectInner{
    width: 80%;
    height: 80%;
    border: 1px solid #555555;
}
img {
    max-width: 100%;
    height: auto;
}
.artifactPortraitContainer{
    height: 60px;
    width: 60px;
    position: relative;
    margin-bottom: 10px;
    cursor: pointer;
    opacity: 0.5
}

.artifactSelected{
    opacity: 1;
}

img {
    max-width: 100%;
    height: auto;
}
.tooltipHover {
  width: 300px;
  position: absolute;
  z-index: 10;
  border: 1px solid #eeeeee;
  text-align: left;
}

.tooltipTitleContainer {
  background: #636363;
  color: white;
  padding: 10px;
}

.tooltipTitleContainer h3 {
  font-size: 1em;
  margin: 0;
}

.tooltipDescriptionContainer {
  background: rgba("220, 220, 220, 0.3");
  padding: 10px;
  color: white;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}

.tooltipDescriptionContainer p {
  font-weight: bold;
  font-size: 0.8em;
  margin: 0;
}

.startButton {
  color: white;
  background: #3b9720 no-repeat;
  border: none;
  font-family: "Rajdhani", sans-serif;
  font-size: 2em;
  text-shadow: 2px 2px 0px #000000;
  cursor: pointer;
  border-style: outset;
  border-color: #3b9720;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 250px;
}

.startButton:hover {
  background: #5bc23b no-repeat;
}

